import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import { Button } from 'components/common';
import SurveyHeader from './surveyHeader';
import Label from 'components/common/form/Label';
import { classNames } from 'utils';
import Spinner from './surveySpinner';
import { toast } from 'react-toastify';

function getFormInitialData(formSchema) {
  let data = Object.keys(formSchema).reduce((acc, key) => {
    const field = formSchema[key];
    acc[key] = field.type === "toggle" || field.type === "toggleWithConditional" ? false : "";
    return acc;
  }, {})

  return [data];
}


function updateLastValue(str, newValue) {
  const parts = str.split('.');

  if (parts.length > 0) {
    parts[parts.length - 1] = newValue;
  }

  return parts.join('.');
}

function LinesModal({ isOpen, onClose}) {
  if (!isOpen) return null;
  return (
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
              <p className="text-gray-700">
                This Analog Lines Audit section of the survey summarizes all of the line information you identified in other sections of the survey.  The lines in this audit section will be the lines that get installed on the EPIK so it is essential that they are all listed here.  Please review each line carefully to make sure that all needed lines are added here before submitting the survey for review.
              </p>
              <button
                  onClick={onClose}
                  className="mt-4 bg-[#3b5aa9] text-white px-4 py-2 rounded-lg hover:bg-[#2a4579] focus:outline-none focus:ring-2 focus:ring-[#3b5aa9]"
              >
                  Close
              </button>
          </div>
      </div>
  );
}

const Lines = ({
  setCurrentStep,
  currentStep,
  form,
  saveForm,
  getSubmittedForm,
  formTitle
}) => {
  const [initialData, setInitialData] = useState();
  const [conditionalStates, setConditionalStates] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [linesModal, setLinesModal] = useState(form?.name === "Survey - Lines Audit");

  const lineRefs = useRef([]);

  const { register, trigger, handleSubmit, control, reset, watch, clearErrors, getValues, resetField, setValue, formState: { errors } } = useForm({
    defaultValues: {
      fields: initialData
    },
    mode: "onChange"
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "fields"
  });

  const watchedFields = useWatch({ control, name: 'fields' });

  useEffect(() => {
    const debounce = (func, delay) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), delay);
      };
    };

    const updateDependentFields = debounce(() => {
      let valuesToUpdate = [];

      watchedFields?.forEach((field, index) => {
        Object.keys(field).forEach(key => {
          const fieldData = form?.form_schema[key];
          if (fieldData?.condition && Object.keys(fieldData?.condition).length) {
            Object.keys(fieldData?.condition).forEach(parentKey => {
              const parentValue = fieldData?.condition[parentKey];
              const actualParentValue = getValues(`fields.${index}.${parentKey}`);
              if (actualParentValue !== parentValue) {
                valuesToUpdate.push(`fields.${index}.${key}`)
                // setValue(`fields.${index}.${key}`, null, { shouldDirty: true });
              }
            });
          }
        })
      });

      valuesToUpdate?.forEach((keys) => {
        setValue(keys, null, { shouldDirty: true });
      })
    }, 300);

    updateDependentFields();
  }, [getValues, setValue, form?.form_schema, watchedFields]);

  async function savedForm() {
    setLoading(true)
    let data = await getSubmittedForm(form?.id);

    if (data?.length) {
      setInitialData(data[0]?.submission_data)
    } else {
      setInitialData(getFormInitialData(form?.form_schema))
    }
    setLoading(false)
  }

  useEffect(() => {
    savedForm()
  }, []);

  useEffect(() => {
    reset({ fields: initialData });
  }, [initialData, reset]);

  const handleAddLine = (e) => {
    append({});
    setTimeout(() => {
      const index = fields.length;
      if (lineRefs.current[index]) {
        lineRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 0);

    e.preventDefault();
  };

  function getError(index, name){
    let nameWithoutPrefix = name.split('.')[2]

    if(errors?.fields && Object.keys(errors?.fields).length){
      return !!errors.fields?.[index]?.[nameWithoutPrefix]
    }

    return false
  }

  const handleRemoveLine = (index) => {
    const line = lineRefs.current[index];
    if (line) {
      line.classList.add('animate-slideOut');
      setTimeout(() => {
        remove(index);
        if (lineRefs.current[index - 1]) {
          lineRefs.current[index - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 500);
    }
  };

  const setRef = (node, index) => {
    if (node) {
      lineRefs.current[index] = node;
    }
  };

  const onSubmit = async (data) => {
    data = getValues()

    let isValid = await trigger();

    if(!isValid){
      return
    }

    let response = await saveForm(data?.fields, form?.id);

    if (response?.message !== 'Form submitted' && response?.message !== 'Submission Updated') {
      toast.error(`Unable to save data`)
      return
    }

    setCurrentStep(1)
  };

  const onError = async (data) => {
    data = getValues()
    let skipValidation = false;

    data?.fields?.forEach((record) => {
      for (let [key, value] of Object.entries(record)) {
        if (key?.includes('unableToFill') && value) {
          skipValidation = true;
          break
        }
      }
    })

    if (skipValidation) {
      clearErrors();
      onSubmit(data?.fields, form?.id);
      return;
    }

    let isValid = await trigger();

    if(!isValid){
      toast.error(`Missing required Field`)
    }
  };

  const handleConditionalChange = (name, value) => {
    setConditionalStates(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const evaluateExpression = (expression) => {
    try {
      const func = new Function('return ' + expression);
      return func();
    } catch (error) {
      console.error('Error evaluating expression:', error);
      return false;
    }
  };

  const getExpressionValue = (dynamicValue, expressionKey, allKeys) => {
    if (typeof (dynamicValue) === "object") {
      return false
    }

    const key = allKeys && Object.keys(allKeys).find(template => {
      const evaluated = evaluateExpression(template.replace(/x/g, dynamicValue).replace(/\[object Object\]/g, dynamicValue));
      return evaluated;
    });

    return key ? allKeys[key] : false;
  };

  const renderField = (field, name, index) => {
    let isShown = true
    if (field?.condition && Object.keys(field?.condition).length) {
      Object.keys(field?.condition).forEach(parentKey => {
        const parentValue = field?.condition[parentKey];
        const actualParentValue = watch(updateLastValue(name, parentKey));
        if (actualParentValue !== parentValue) {
          isShown = false
        }
      });
    }

    if (!isShown) {
      return null
    }

    switch (field?.type) {
      case 'number':
        return (
          <div className='my-2'>
            {field?.label && (<Label name={name} label={field?.label} errors={getError(index, name)} options={field?.options} />)}
            <input
              {...register(name, field?.options)}
              placeholder={field.options?.placeholder}
              type="number"
              className="mt-1 w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
            />

          </div>
        )
      case 'text':
        return (
          <div className='my-2'>
            {field?.label && (<Label name={name} label={field?.label} errors={getError(index, name)} options={field?.options} />)}
            <input
              {...register(name, field?.options)}
              placeholder={field.options?.placeholder}
              type={field.type}
              className="mt-1 w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
            />

          </div>
        )
      case 'tel':
        return (
          <div className='my-2'>
            {field?.label && (<Label name={name} label={field?.label} errors={getError(index, name)} options={field?.options} />)}
            <input
              {...register(name, {
                ...field?.options, pattern: {
                  value: /^\+?1?\d{9,15}$/,
                  message: 'Invalid phone number format'
                }
              })}
              placeholder={field.options?.placeholder}
              className="mt-1 w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
            />

          </div>
        )
      case 'textArea':
        return (
          <div className='my-2'>
            {field?.label && (<Label name={name} label={field?.label} errors={getError(index, name)} options={field?.options} />)}
            <textarea
              {...register(name, field?.options)}
              placeholder={field.options?.placeholder}
              className="mt-2 w-full rounded-lg border-gray-300 shadow-sm sm:text-sm"
            />
          
          {field?.bottomLabel && (<Label name={`${name}`} label={field?.bottomLabel} error={true}/>)}
          </div>
        )
      case 'toggle':
        return (
          <div className='my-2'>
            {field?.label && (<Label name={name} label={field?.label} errors={getError(index, name)} options={field?.options} />)}
            <label className="relative inline-block h-8 w-14 cursor-pointer rounded-full bg-gray-300 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-green-500"
            >
              <input
                type="checkbox"
                {...register(name, field?.options)}
                className="peer sr-only"
              />
              <span
                className="absolute inset-y-0 start-0 m-1 size-6 rounded-full bg-white transition-all peer-checked:start-6"
              ></span>            </label>

          </div>
        )
      case 'image':
        let arrName = name?.split('.')
        let hasImageLink = initialData?.[parseInt(arrName[1])]?.[arrName[arrName?.length - 1]]
        return (
          <div className="flex flex-col items-start mt-4">
            {hasImageLink && (
              <img
                src={hasImageLink}
                alt="Preview"
                className="mt-2 w-full h-auto rounded-md"
              />
            )}
            <label
              htmlFor={`${name}-upload`}
              className={classNames(
                "relative inline-flex items-center cursor-pointer",
                field?.options?.readOnly && "opacity-60 cursor-not-allowed"
              )}
            >
              <input
                id={`${name}-upload`}
                type="file"
                accept="image/*"
                className="mt-2 block w-full text-sm file:mr-4 file:rounded-md file:border-0 file:bg-teal-500 file:py-2 file:px-4 file:text-sm file:font-semibold file:text-white hover:file:bg-teal-700 focus:outline-none disabled:pointer-events-none disabled:opacity-60"
                {...register(name, field?.options)}
              />
            </label>
            {field?.label && (
              <div className="mt-2 text-sm">
                <Label name={`${name}-upload`} label={field?.label} errors={getError(index, name)} options={field?.options} />
              </div>
            )}

          </div>
        )
      case 'radio':
        return (
          <div className="my-2">
            <Label name={name} label={field?.label} errors={getError(index, name)} options={field?.options} />
            <fieldset className="grid grid-cols-1 md:grid-cols-2 gap-2">
              <legend className="sr-only">Options</legend>
              {field.selectOptions?.map((option, idx) => (
                <div
                  key={option.value}
                  className="relative flex w-56 items-center justify-center rounded-xl bg-gray-50 px-4 py-3 font-medium text-gray-700"
                >
                  <input
                    type="radio"
                    id={`${name }-${option.value}`}
                    value={option.value}
                    {...register(name,  field?.options )}
                    className="peer hidden"
                  />
                  <label
                    htmlFor={`${name}-${option.value}`}
                    className="peer-checked:border-blue-400 peer-checked:bg-blue-200 absolute top-0 h-full w-full cursor-pointer rounded-xl border"
                  ></label>
                  <div className="peer-checked:border-transparent peer-checked:bg-blue-400 peer-checked:ring-2 absolute left-4 h-5 w-5 rounded-full border-2 border-gray-300 bg-gray-200 ring-blue-400 ring-offset-2"></div>
                  <span className="pointer-events-none z-10">{option.label}</span>
                </div>
              ))}
            </fieldset>
          </div>
        )
      case 'select':
        return (
          <div className="my-2">
            <Label name={name} label={field?.label} errors={getError(index, name)} options={field?.options} />
            <select
              name={name}
              id={name}
              className="mt-1.5 w-full rounded-lg border-gray-300 text-gray-700 sm:text-sm"
              {...register(name, field?.options)}
            >
              <option value={'Not Selected'}>{`Choose ...`}</option>
              {field.selectOptions?.map((option, idx) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>

          </div>
        )
      default:
        return null;
    }
  };

  if (loading) {
    return <Spinner size="large" />
  }

  return (
    <div className="min-h-screen bg-[#e6f0ff] font-poppins">
      <SurveyHeader title={form?.title} setCurrentStep={setCurrentStep} setShowModal={setShowModal} showModal={showModal} />
      <div className="flex flex-col items-center p-4">
        <form
          id='generic-form'
          onSubmit={handleSubmit(onSubmit, onError)}
          className="bg-white w-full max-w-lg p-6 rounded-lg shadow-md"
        >
                {form?.title === "Analog Lines Audit" && (<div className="relative mb-6">
                    <div
                        className={`p-4 bg-[#3b5aa9] text-white rounded-lg shadow-md cursor-pointer transition-transform transform ${showInstructions ? 'scale-105' : 'scale-100'}`}
                        onClick={() => setShowInstructions(!showInstructions)}
                        onKeyDown={(e) => { if (e.key === 'Enter') setShowInstructions(!showInstructions); }}
                        role="button"
                        tabIndex={0}
                    >
                        <div className="flex items-center justify-between">
                            <p className="text-lg font-semibold">Line Audit Instructions</p>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path d="M4.5 9a.75.75 0 0 1 .75-.75h11.5a.75.75 0 0 1 0 1.5H5.25A.75.75 0 0 1 4.5 9Zm.75 4.5a.75.75 0 0 1 .75-.75h5a.75.75 0 0 1 0 1.5h-5a.75.75 0 0 1-.75-.75Zm6 4.5a.75.75 0 0 1 .75-.75h5a.75.75 0 0 1 0 1.5h-5a.75.75 0 0 1-.75-.75Z" />
                            </svg>
                        </div>
                        {showInstructions && (
                            <div className="mt-4">
                                <p className="text-sm text-white-700">{form?.form_schema['instructionLineAudit']?.options?.placeholder}</p>
                            </div>
                        )}
                    </div>
          </div>)}
          {fields.map((item, index) => (
            <div key={item.id} ref={(node) => setRef(node, index)} className="mb-6">
              <div className="flex items-center my-4">
                <div className="flex-grow">
                  <hr className="border-t-2 bg-[#3b5aa9]" />
                </div>
                {!["Survey - Backboard Info", "Survey - Network Info Task"].includes(form?.name) && (<span className="px-4 text-[#3b5aa9] font-bold">Line {index + 1}</span>)}
                <div className="flex-grow">
                  <hr className="border-t-2 bg-[#3b5aa9]" />
                </div>
              </div>
              {Object.keys(form?.form_schema).map((key) => {
                const field = form?.form_schema[key];
                return (
                  <div key={key} className="mt-4">
                    {renderField(field, `fields.${index}.${key}`, index)}
                  </div>
                );
              })}
              {!["Survey - Backboard Info", "Survey - Network Info Task"].includes(form?.name) && (<button
                type="button"
                className="mt-4 flex items-center text-red-500"
                onClick={() => handleRemoveLine(index)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
                Remove Line
              </button>)}
            </div>
          ))}
          {!["Survey - Backboard Info", "Survey - Network Info Task"].includes(form?.name) && (<div>
            <button
              onClick={handleAddLine}
              className="flex items-center text-[#3b5aa9] mt-4 font-bold hover:text-[#2a4275]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-12 w-12"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                  clipRule="evenodd"
                />
              </svg>
              <span>Add new Line</span>
            </button>

          </div>)}
          <div className="mt-6 flex justify-between">
            <Button
              type="submit"
              form="generic-form"
              className="cursor-pointer transition-all bg-[#3b5aa9] text-white px-6 py-2 rounded-lg
                            border-[#3b5aa9]
                            border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px]
                            active:border-b-[2px] active:brightness-90 active:translate-y-[2px]"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
      <LinesModal isOpen={linesModal} onClose={() => setLinesModal()}/>
    </div>
  );
};

export default Lines;
